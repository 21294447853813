.topnav .navbar-nav .nav-item .nav-link.active h5 {
  color: #5b73e8;
}

i.dripicons-arrow-thin-left {
  display: none;
}
i.dripicons-user {
  display: none;
}

@media (max-width: 767px) {
 
  .profile_details_steps ul.form-vertical.nav.flex-column {
    display: flex;
    width: 100%;
    flex-direction: row !important;
    height: auto;
    margin-bottom: 20px;
    position: relative;
  }
  .bg-light .flex_skill_head > .border {
    margin: 0 !important;
    width: 33.33% !important;
  }
  .bg-light .skill_w_exp .list-group-item .d-flex.align-items-center {
    display: inline-block !important;
    width: 100%;
  }
  .bg-light
    .skill_w_exp
    .list-group-item
    .d-flex.align-items-center
    > .avatar-sm {
    float: left;
    margin: 0 10px 0 0 !important;
    width: 40px;
  }
  .bg-light
    .skill_w_exp
    .list-group-item
    .d-flex.align-items-center
    > .flex-grow-1 {
    float: left;
    width: calc(100% - 50px);
  }
  .bg-light
    .skill_w_exp
    .list-group-item
    .d-flex.align-items-center
    > .me-2.w-15 {
    float: left;
    margin: 15px 4% 0 0 !important;
    width: 48% !important;
  }
  .bg-light
    .skill_w_exp
    .list-group-item
    .d-flex.align-items-center
    > div:nth-of-type(4) {
    float: left;
    width: 48%;
    margin-top: 15px !important;
  }
}
@media (max-width: 990px) {
  /*  */
  .page-content {
    margin-top: 70px !important;
  }
  header#page-topbar h5.font-size-14.text-white {
    font-size: 12px !important;
  }
  .row .user_details_card {
    border: 0 !important;
    border-radius: 0;
  }
  .row .user_details_card .profile_card {
    top: 0;
    margin: 0 !important;
    position: fixed;
    left: 0;
    z-index: 9999;
    border: 0 !important;
    height: 100%;
    padding: 30px !important;
    border-radius: 0;
    width: 100%;
    background-color: #fff;
    max-width: 540px;
    transition: transform 0.5s ease;
    transform: translateX(-100%);
  }

  .scrollable_profile {
    height: 100%;
    overflow-y: auto;
  }
  .main-content.profile_open:before {
    content: "";
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: block;
    background: #00000094;
  }
  i.dripicons-arrow-thin-left {
    display: block;
    cursor: pointer;
  }
  i.dripicons-arrow-thin-left {
    font-size: 17px;
    padding: 5px 8px 3px;
    background: #28419e;
    border-radius: 50px;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  i.dripicons-arrow-thin-left:before {
    color: #fff;
  }
  /* i.dripicons-user {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 999;
    width: 34px;
    height: 34px;
  } */
  i.dripicons-user {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 999;
    background: #28419e;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    display: block !important;
    font-style: normal;
    border-radius: 5px;
  }
  i.dripicons-user:before {
    /* font-size: 18px;
    padding: 8px 8px;
    background: #28419e; */
    color: #fff;
    /* border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0; */
  }
  .row
    .user_details_card
    .profile_card
    .d-flex.justify-content-center.mt-3.mt-sm-0 {
    width: max-content;
  }
  .row .user_details_card .profile_card .flex-grow-1.mt-2 .text-center {
    text-align: left !important;
  }

  /*  */
  nav#navigation {
    position: fixed;
    bottom: 0;
    border: 0 !important;
    box-shadow: 0 0 5px #cdcdcd;
  }
  nav#navigation ul.navbar-nav.flex-wrap {
    display: inline !important;
    width: 100%;
  }
  nav#navigation ul.navbar-nav.flex-wrap li {
    display: inline !important;
    float: left;
    width: 20%;
  }
  nav#navigation ul.navbar-nav.flex-wrap li button {
    padding: 10px 0 !important;
    width: 100%;
  }

  nav#navigation
    ul.navbar-nav.flex-wrap
    li
    button
    p.text-muted.mb-0.font-size-10 {
    display: none;
  }
  nav#navigation ul.navbar-nav.flex-wrap li button h5 svg {
    display: block;
    width: 100%;
    margin: 0 0 5px;
  }
  nav#navigation ul.navbar-nav.flex-wrap li button h5 {
    margin: 0 !important;
    font-size: 11px !important;
  }
}
@media (max-width: 375px) {
  nav#navigation ul.navbar-nav.flex-wrap li button h5 {
    margin: 0 !important;
    font-size: 9px !important;
  }
}
